<template>
  <div id="home" class="content-container">
    <div class="banner-container" v-if="!canShow">
      <swiper
        class="banner-swiper"
        ref="bannerSwiper"
        :options="bannerSwiperOption"
      >
        <swiper-slide>
          <img class="banner-img" src="../assets/banner/1.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner/2.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner/3.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner/4.png" />
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <swiper class="swiper" ref="contentSwiper" :options="swiperOption">
      <swiper-slide v-if="canShow" class="banner-slide">
        <div class="banner-container">
          <swiper
            class="banner-swiper"
            ref="bannerSwiper"
            :options="bannerSwiperOption"
          >
            <swiper-slide>
              <img class="banner-img" src="../assets/banner/1.png" />
            </swiper-slide>
            <swiper-slide>
              <img class="banner-img" src="../assets/banner/2.png" />
            </swiper-slide>
            <swiper-slide>
              <img class="banner-img" src="../assets/banner/3.png" />
            </swiper-slide>
            <swiper-slide>
              <img class="banner-img" src="../assets/banner/4.png" />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="function-container">
          <h2>智能测量手机&蓝牙测距仪无缝对接</h2>
          <div class="imgs-container">
            <img src="../assets/function/1.png" />
            <!-- <img src="../assets/function/2.png" />
            <img src="../assets/function/3.png" /> -->
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="function-container">
          <h2>多款测距仪智能轻松对接</h2>
          <div class="imgs-container">
            <img src="../assets/function/2.png" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="function-container">
          <h2>量房更轻松,更快捷</h2>
          <div class="imgs-container">
            <img src="../assets/function/3.png" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="app-container">
          <h2>智能量房 一键出图</h2>
          <h4>绘制完成,一键查看/下载CAD平面图,立面图,面积清单,3D户型</h4>
          <div class="imgs-container">
            <img src="../assets/app/1.jpg" />
            <img src="../assets/app/2.jpg" />
            <img src="../assets/app/3.png" />
            <img src="../assets/app/4.jpg" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="app-container">
          <h2>19大功能 一应俱全</h2>
          <h4>门窗 地板 瓷砖 吊顶 涂料 砖块 板材算量功能统统体现,实时分享</h4>
          <div class="imgs-container">
            <img src="../assets/info/1.png" />
            <img src="../assets/info/2.png" />
            <img src="../assets/info/3.png" />
            <img src="../assets/info/4.png" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div id="about-us" class="about-container">
          <h2>家易量&nbsp;&nbsp;让量房更简单</h2>
          <p>
            家易量APP是手机量房软件，帮助测量人员，通过蓝牙激光测距仪，连接手机实现快速，无纸，自动出图和算量清单的量房软件。
            在手机端，即时进行，数据记录，户型绘制，生成：3D户型，cad图纸，材料清单，现场拍照记录等功能。
            测量人员，看教程简单适应，就可以专业测量。
          </p>
          <p style="margin-top: 30px">主要核心点：</p>
          <p>
            快速，无需纸笔，数据即时传输，图纸直接生成，清单直接导出，真正实现，现场测量全盘输出。
            使用便捷 缩短测量时间 提升测量师傅的专业度。
          </p>
          <Footer style="margin-top: 10px"></Footer>
        </div>
      </swiper-slide>
      <!-- <swiper-slide>
        <div class="download-container">
          <div class="download-content">
            <h2>家易量App下载</h2>
            <h4>APP同时支持iOS和Android系统</h4>
            <div class="download-imgs">
              <div style="display:flex;flex-direction:column;align-items:center;">
                <img src="../assets/download.png" class="download-img" />
                <a  v-if="!canShow" style="font-size:12px;">Android下载</a>
              </div>
               <div style="display:flex;flex-direction:column;align-items:center;">
                <img src="../assets/download.png" class="download-img" />
                <a v-if="!canShow" style="font-size:12px;">IOS下载</a>
              </div>
            </div>
            <span style="margin-top:14px;font-size:13px;">Android系统4.3及以上的版本都可适配</span>
          </div>
          <Footer></Footer>
        </div>
      </swiper-slide> -->

      <div v-if="canShow" class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Footer from "@/components/Footer";

import { mixinDevice } from "@/util/mixin";

export default {
  name: "Content",
  mixins: [mixinDevice],
  components: {
    Swiper,
    SwiperSlide,
    Footer,
  },
  data() {
    return {
      bannerSwiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption: {
        direction: "vertical",
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  mounted() {
    this.bus.$on('on-slide-change',this.onSlideChange);
  },
  computed: {
    canShow() {
      return !this.isMobile();
    },
  },
  methods: {
    onSlideChange(name) {
      // console.log("onSlideChange")
      if (name === "home") {
        this.$refs.contentSwiper.$swiper.slideTo(0, 0);
      } else if (name == "info") {
        this.$refs.contentSwiper.$swiper.slideTo(1, 0);
      } else if (name == "about-us") {
        this.$refs.contentSwiper.$swiper.slideTo(6, 0);
      } else if (name == "download") {
        this.$refs.contentSwiper.$swiper.slideTo(7, 10);
      }
    },
  },
};
</script>

<style lang="less" >
.content-container {
  width: 100%;
  padding-top: 76px;
  overflow: scroll;

  .banner-container {
    width: 100%;
    margin-top: 60px;
    position: relative;

    @media screen and (max-width: 750px) {
      margin-top: 0px;

      .banner-swiper {
        height: 170px;
      }
    }

    .banner-img {
      width: 100%;
      height: auto;
    }

    .banner-bottom {
      width: 100%;
      position: absolute;
      bottom: 20px;
      text-align: center;

      h2 {
        font-size: 24px;
      }

      h4 {
        font-size: 18px;
      }
    }
  }

  .swiper {
    width: 100%;
    height: calc(100vh - 76px);
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 12 * 2;
    background-color: #fefefe;
  }

  .function-container {
    width: 100%;
    height: 95%;
    text-align: center;
    padding: 12px 30px;

    .imgs-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      overflow-y: hidden;
      overflow-x: scroll;

      img {
        height: auto;
        max-height: 90%;
        max-width: 80%;
        margin-right: 12px;
      }
    }

    @media screen and (max-width: 750px) {
      padding: 12px;
      .imgs-container {
        img {
          max-width: 90%;
        }
      }
    }
  }

  .app-container {
    width: 100%;
    height: 95%;
    text-align: center;
    padding: 12px 30px;

    .imgs-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      overflow-y: hidden;
      overflow-x: auto;

      img {
        height: 80%;
        margin-right: 12px;
      }
    }

    @media screen and (max-width: 750px) {
      padding: 12px;
    }
  }

  .about-container {
    max-width: 766px;
    text-align: left;
    padding: 0 12px;

    h2 {
      font: 44px/104px "\5FAE\8F6F\96C5\9ED1";
      color: #333333;
      text-align: center;
    }

    p {
      font: 16px/33px "\5FAE\8F6F\96C5\9ED1";
      padding: 10px 0 0;
      margin: 0;
    }

    @media screen and (max-width: 750px) {
      h2 {
        font: 30px/80px "\5FAE\8F6F\96C5\9ED1";
      }

      p {
        font: 14px/20px "\5FAE\8F6F\96C5\9ED1";
        padding: 10px 0 0;
        margin: 0;
      }
    }
  }

  .download-content {
    max-width: 766px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .download-imgs{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .download-img {
      max-width: 200px;
      max-height: 200px;

      &:first-child{
        margin-right: 20px;
      }
    }

    @media screen and (max-width: 750px) {
      .download-img {
        max-width: 150px;
        max-height: 150px;
      }
    }
  }
}
</style>